import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

function RapidAPISupport() {
    return (<Box>
        <Typography>
            We're excited to announce that IPDetective is now available at RapidAPI.
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            RapidAPI is a platform that allows developers to access thousands of APIs (Application Programming Interfaces) in one place. 
            It simplifies the process of integrating various APIs into applications by providing a unified interface for discovery, testing and usage. 
            Developers can find APIs for a wide range of functionalities, including weather data, social media integration, payment processing and much more.
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            If you are interested in using the IPDetective API via RapidAPI <Link href="https://rapidapi.com/IPDetective/api/ipdetective">visit here</Link>.
        </Typography>
    </Box>)
}

export default RapidAPISupport