
import * as React from 'react';
import './App.css';
import IPDetective from './component/IPDetective';
import {  ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles'
import * as config from './config'

const getUser = () => {
  const userStr = localStorage.getItem('user')
  if (userStr === "" || userStr === null || userStr === undefined) {
    return {"uuid":"","email":"","api_key":"","created_at":1660594812,"request_limit":0,"lookup_limit":0,"request_hits":0,"lookup_hits":0}
  }
  return JSON.parse(userStr)
}


function App() {
  const [user, setUser] = React.useState(getUser())
  const [appConfig, setAppConfig] = React.useState(config)
  const theme = createTheme({
    palette: {
      primary: {
        main: config.PRIMARY_COLOR
      },
      background: {
        default: '#e4f0e2'
      }
    }
  })
  React.useEffect(() => {
    const userStr = localStorage.getItem('user')
    if (userStr === "" || userStr === null || userStr === undefined) {
      return
    }
    setUser(JSON.parse(userStr))
    const hostname = window.location.hostname
    if (hostname == "ipdetective.io") {
      setAppConfig(config)
    } else if (hostname == "emaildetective.io") {
      setAppConfig(config)
    }
    
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <IPDetective user={user} setUser={setUser} config={appConfig}></IPDetective>
      </div>
    </ThemeProvider>
  )
}
export default App;
