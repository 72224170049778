import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const Code = ({children}) => {
    console.log(children)
    return (
      <SyntaxHighlighter useInlineStyles={true} language="javascript" style={docco}>
        {children}
      </SyntaxHighlighter>
    )
  }

function DetectingBotsWithJavascript() {
    const loginHandler = `const express = require('express')
const app = express()
const port = 3000

app.get('/', (req, res) => {
  var data = req.body;
  if (data.username != "andrew" || data.password != "andrewsPassword") {
    res.send('incorrect username or password')
  }
  res.send('Login successful')
})

app.listen(port, () => {
  console.log(\`Example app listening on port \${port}\`)
})`

    const loginHandlerWithIPDetective = `const express = require('express')
const app = express()
const port = 3000

app.get('/', (req, res) => {
  var data = req.body;
  if (data.username != "andrew" || data.password != "andrewsPassword") {
    res.send('incorrect username or password')
  }
  const ip = req.connection.remoteAddress
  const url = "https://api.ipdetective./ip/" + ip
  fetch(url, {
    header: {
      "x-api-key": process.env.IPDETECTIVE_API_KEY
    }
  }).then(response => {
    response.json().then(body => {
      if (body.bot) {
        res.send('client IP address detected as a non-human user by IPDetective')
        return
      }
      res.send('Login successful')
    })
  })
})

app.listen(port, () => {
  console.log(\`Example app listening on port \${port}\`)
})`


    return (<Box>
        <Typography>
            This blog post will go over a simple implementation of using IPDetective to help detect or prevent non-human users from using a login REST endpoint.
            First thing first, lets show what the current javascript express REST endpoint looks like.
        </Typography>
        <Code>
            {loginHandler}
        </Code>
        <Typography>
            This endpoint is rather simple. 
            The endpoint function just validates that the username provided is 'andrew' and the password is 'andrewsPassword'.
            If this criteria is met then a 'Login successful' message is sent to the client.
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            Now lets get the clients IP address and leverage the IPDetective REST API to see if the client's IP address is a bot (non-human user) or not.
        </Typography>
        <Code>
            {loginHandlerWithIPDetective}
        </Code>
        <Typography>
            In the function above, the 'fetch' library is used to make a HTTP request to the IPDetective REST API. 
            Also the IPDetective api key is stored as an environment variable 'IPDETECTIVE_API_KEY'.
            The client IP address is obtained using 'req.connection.remoteAddress'. 
            Take note that the client IP address may have to be obtained in a different manner if the express service is deployed behind a proxy.
            Finally we bring this all together to check if the client IP address is a bot or non-human user. 
            If IPDetective detects the client ip address as a bot then the following message is returned to the end user 'client IP address detected as a non-human user by IPDetective'. 
        </Typography>
    </Box>)
}

export default DetectingBotsWithJavascript