import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

function ProtectingWebsiteFromSpamBots() {
    return (<Box>
        <p >As you manage your website, you need to pay attention to
        spam bots that may log into your website and participate in activities such as
        filling out reviews or contact forms. It is important to ensure that all
        your website visitors are real people and not bots. Getting too many bots on
        your website can be misleading, especially if you heavily rely on the number of
        users or subscribers to make certain decisions. </p>

        <p >In this article, I will discuss everything you need to know
        about spam bots and why you need to fight them. I will also discuss the most
        effective methods you can use to prevent these bots from participating in any
        activities on your website. Without any further ado, let’s jump right in!</p>

        <p >&nbsp;</p>

        <Typography variant="h5" >What are spam bots? </Typography>

        <p >Spam bots are malicious programs designed to appear as real
        users when they access your website. If your website doesn’t have measures to
        stop spam bots, they can create accounts and even fill out contact forms or
        reviews. Most of these bots are designed to scrap data from websites or even
        inject malware if they gain enough access. </p>

        <p >The people who design these bots usually aim to get access
        to as many websites as possible without having to do it manually. As a website
        owner/manager, your goal is to only get real people to visit and use your site
        and not these spam bots. That is why it is important to put in place measures
        to prevent them from participating in any activities on your website. </p>

        <p >&nbsp;</p>

        <Typography variant="h5">How to prevent spam bots from using your website</Typography>

        <h3 style={{
            marginLeft: '.5in',
            textIndent: '-.25in'
        }}>1.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Install a reCaptcha plugin on your website</h3>

        <p style={{
            marginLeft: '.25in'
        }}>ReCaptcha is a fraud detection tool designed by Google that
        recognizes bots automatically and prevents them from accessing your website.
        This tool is free and can be installed on any website with ease. With this tool
        installed, all users coming to your website will have to verify that they are not
        bots before being allowed to fill out any forms or create an account on your
        website. </p>


        <h3 style={{
            marginLeft: '.5in',
            textIndent: '-.25in'
        }}>2.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Email confirmation should be a must.</h3>

        <p style={{
            marginLeft: '.25in'
        }}>All users who have subscribed to your website or web app
        need to confirm their emails to ensure that only genuine users are allowed to
        participate in any activities on your website. Email confirmation involves
        sending a code to the user's email that they need to use to finalize the
        registration process. Most spam bots don’t have the ability to complete this
        process. </p>


        <h3 style={{
            marginLeft: '.5in',
            textIndent: '-.25in'
        }}>3.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Validating IP address using IPDetective</h3>

        <p style={{
            marginLeft: '.25in'
        }}>Another tested method for detecting bots from using your website
        is detecting the IP addresses of all traffic coming to your website. One of the
        reliable tools you can use to detect IP addresses is <a
        href="https://ipdetective.io/">IPDetective</a>. This tool will determine if the
        IP address is from actual user devices, such as a computer or mobile device or
        if it is from other sources, such as data centers, botnets, proxies and VPNs. </p>

        <p style={{
            marginLeft: '.25in'
        }}>The goal of using this tool is to block all traffic that is
        not from real users. For instance, traffic that comes from data centers is not
        from real users. Such traffic can be blocked from accessing or participating in
        any activities on your website. Alternatively, you could also use ReCaptcha
        whenever this tool detects that the traffic is not coming from real user
        devices. </p>


        <p style={{
            marginLeft: '.25in'
        }}>The good news is that IPDetective is free for 1000 daily
        requests. Those who need to process more requests can subscribe to the premium
        plans that range from $10/month up to $100/month, depending on the number of
        daily requests you intend to process.  </p>

        <h3 style={{
            marginLeft: '.5in',
            textIndent: '-.25in'
        }}>4.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Clicking and interactions times</h3>

        <p style={{
            marginLeft: '.25in'
        }}>Real users will usually take a couple of seconds or minutes
        between when they click a certain page on your site and interact with the
        content on it. Spam bots are usually scripted to interact with the site and
        execute the intended activity as quickly as possible. This usually leads to a
        high bounce rate for your website. If you detect unusual levels of bounce rate,
        it could be because of spam bots that don’t spend any meaningful time on your
        site. </p>

        <h3 style={{
            marginLeft: '.5in',
            textIndent: '-.25in'
        }}>5.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Use anti-spam plugins</h3>

        <p style={{
            marginLeft: '.25in'
        }}>Anti-spam plugins are designed to detect any kind of spam
        activities on your website and prevent them from happening. Some of these
        activities may include posting malicious links in blog comments or inputting
        emails that don’t seem realistic. Most anti-spam plugins have a free version
        with the option of upgrading to the premium if you need more advanced features.
        </p>

        <h3 style={{
            marginLeft: '.5in',
            textIndent: '-.25in'
        }}>6.<span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>Block traffic from certain countries</h3>

        <p style={{
            marginLeft: '.25in'
        }}>If you notice that most spam bots are from a specific
        country or region, you can choose to temporarily block that country until you
        come up with ways of preventing the bots from using your site. Of course, you must
        determine the consequences of blocking a specific country before taking action.
        </p>

        <p style={{
            marginLeft: '.25in'
        }}>For instance, consider looking at the revenue contribution
        as a result of the traffic from that country. If the country that is generating
        the most bots doesn’t have any significant contribution to your website’s
        revenue, the decision to block traffic from that country should be pretty easy.
        The decision would be more complicated if users from that country add value to
        your site. </p>

        <p style={{
            marginLeft: '.25in'
        }}>You can block traffic from specific countries using Google
        Analytics. While in Google Analytics, go to the admin tab and click <b>Filters&gt;New
        Filter</b>. You will then see the option of blocking traffic from specific
        countries. </p>

        <p >&nbsp;</p>

        <Typography variant="h5" style={{
            fontWeight: 'bold'
        }}>Final thoughts</Typography>

        <p >Fighting spam bots should be an ongoing activity because the
        people creating them are always looking for ways to bypass the measures put in
        place to block them. That is why it is important to do periodic analysis to
        determine if the methods you are using to prevent spam bots are still
        effective and have not been bypassed.</p>
    </Box>)
}

export default ProtectingWebsiteFromSpamBots