import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress  from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import Typography from '@mui/material/Typography';
import { Button, Container, Divider, TextField } from '@mui/material';
import colors from '../colors'

import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import * as config from '../config'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { fontStyle } from '@mui/system';


function createData(
  name,
  calories,
  fat,
  carbs,
  protein
) {
  return { name, calories, fat, carbs, protein };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = ({open, setOpen, user, setUser}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const rotateApiKey = () => {
    fetch(config.API_URL + "/rotateApiKey", {
      method: "POST",
      headers: {
        "x-api-key": user.api_key
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        let localUser = JSON.parse(localStorage.getItem("user"))
        localUser.api_key = body.api_key
        localStorage.setItem("user", JSON.stringify(localUser))
        console.log(body)
        setUser(localUser)
        window.location.reload()
      })
    })
    
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Rotate your API Key?"}</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={rotateApiKey}>Rotate</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


function BasicTable({rows}) {
  ;
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow key="header">
            <TableCell>Time ago</TableCell>
            <TableCell align="right">Action</TableCell>
            <TableCell align="right">IP Address</TableCell>
            <TableCell align="right">Bot</TableCell>
            <TableCell align="right">Error</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.uuid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.time_ago}
              </TableCell>
              <TableCell align="right">{row.action}</TableCell>
              <TableCell align="right">{row.ip}</TableCell>
              <TableCell align="right">{JSON.stringify(row.bot)}</TableCell>
              <TableCell align="right">{row.error}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SnackBar({open, setOpen, message}) {
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Stack spacing={4} sx={{ width: '100%' }}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}


const Account = ({user, setUser}) => {
  console.log("USer: ", user)
  const [usage, setUsage] = React.useState({
    "request_limit":0,
    "lookup_limit":0,
    "request_hits":0,
    "lookup_hits":0,
    "plan": "free"
  })
  const [reqPerc, setReqPerc] = React.useState(0)
  const [queryPerc, setQueryPerc] = React.useState(0)
  const [apiKey, setApiKey] = React.useState("********************")
  const [apiKeyToggle, setApiKeyToggle] = React.useState(false)
  const [message, setMessage] = React.useState("Successfully copied api key to clipboard")
  const [open, setOpen] = React.useState(false)
  const [rotateOpen, setRotateOpen] = React.useState(false)
  const [updateCardUrl, setUpdateCardUrl] = React.useState("")
  const [cancelCardUrl, setCancelCardUrl] = React.useState("")
  const [userAudit, setUserAudit] = React.useState([])
  const [paddleDisabled, setPaddleDisabled] = React.useState(true)


  React.useEffect(() => {
    if (user.uuid === "") {
      window.location.replace("/login")
      return
    }
    fetch(config.API_URL + "/usage", {
      headers: {
        "x-api-key": user.api_key
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        console.log(body)
        body.plan = "FREE"
        // TODO: to hacky but whatever :) 
        if (body.request_limit === 10000) {
          body.plan = "BASIC"
        } else if (body.request_limit === 30000) {
          body.plan = "BASIC+"
        } else if (body.request_limit === 80000) {
          body.plan = "PREMIUM"
        } else if (body.request_limit === 200000) {
          body.plan = "PREMIUM+"
        }
        setUsage(body)
        setReqPerc(~~(body.request_hits/body.request_limit*100))
        setQueryPerc(~~(body.lookup_hits/body.lookup_limit*100))
      })
    })
    fetch(config.API_URL + "/userAudit", {
      headers: {
        "x-api-key": user.api_key
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        console.log(body)
        const now = Math.floor(Date.now() / 1000)
        console.log("time ago: ", now)
        let reversedAudit = []
        let plural = ""
        for (let row of body) {
          let timeSpan = "second"
          let timeAgo = now - row.created_at
          if (timeAgo > 86400) {
            timeSpan = "day"
            timeAgo = ~~(timeAgo/86400)
          } else if (timeAgo > 3600) {
            timeSpan = "hour"
            timeAgo = ~~(timeAgo/3600)
          } else if (timeAgo > 60) {
            timeSpan = "minute"
            timeAgo = ~~(timeAgo/60)
          }
          if (Number(timeAgo) > 1) {
            plural = "s"
          }
          row.time_ago = `${timeAgo} ${timeSpan}${plural} ago`
          reversedAudit.push(row)
        }
        setUserAudit(reversedAudit)
      })
    })
    fetch(config.API_URL + "/paddleManagement", {
      headers: {
        "x-api-key": user.api_key
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        console.log(body)
        setUpdateCardUrl(body.update_url)
        setCancelCardUrl(body.cancel_url)
        setPaddleDisabled(false)
      })
    })  }, [])


  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }
  return (
    <Box>
        <AlertDialogSlide open={rotateOpen} setOpen={setRotateOpen} user={user} setUser={setUser}></AlertDialogSlide>
        <Typography variant="h4">Hello {user.email}</Typography>
        <Divider style={{
          marginBottom: '1em'
        }}></Divider>
        <Box style={{
          textAlign: "left"
        }}>
          <Typography>
            Welcome to IPDetective, you can search for IP addresses directly through the <Link href="/search">website</Link>. 
            If you are interested in using the API please check out the <Link href="/api">documentation</Link>. 
          </Typography>
        </Box>
        <Typography variant="h4" style={{
          marginTop: '1em'
        }}>Stats</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">Requests</Typography>
            <Typography>Requests: {usage.request_hits} out of {usage.request_limit}</Typography>
            <LinearProgress variant="determinate" value={reqPerc}/>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6">IP Queries</Typography>
            <Typography>IP Queries: {usage.lookup_hits} out of {usage.lookup_limit}</Typography>
            <LinearProgress variant="determinate" value={queryPerc}/>
          </Grid>
          <Grid item xs={12} md={4}> 
            <Typography variant="h6">Plan</Typography>
            <Typography>{usage.plan}</Typography>          
          </Grid>
        </Grid>

        <Typography variant="h4" style={{
          marginTop: '1em'
        }}>My API key</Typography>
        <Divider style={{
          marginBottom: '1em'
        }}></Divider>
        <Box style={{
          display: "flex"
        }}>
          <TextField fullWidth value={apiKey} />
          <Button onClick={() => {
            copyTextToClipboard(user.api_key)
            setOpen(true)
          }}>Copy</Button>
          <Button onClick={() => {
            if (apiKeyToggle) {
              setApiKey("********************")
              setApiKeyToggle(false)
              return
            }
            setApiKey(user.api_key)
            setApiKeyToggle(true)
          }}>{apiKeyToggle ? "HIDE": "SHOW"} API Key</Button>
          <Button disabled={false} onClick={() => {
            setRotateOpen(true)
          }}>Rotate</Button>
        </Box>
        <SnackBar open={open} setOpen={setOpen} message={message}></SnackBar>
        <Typography variant="h4" style={{
          marginTop: '1em'
        }}>Management</Typography>
        <Divider style={{
          marginBottom: '1em'
        }}></Divider>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Link href={updateCardUrl}>
              <Button disabled={paddleDisabled}>Update Card</Button>
            </Link>
          </Grid>
          <Grid item xs={12} md={6}> 
          <Link href={cancelCardUrl}>
            <Button disabled={paddleDisabled} color="error">Cancel Plan</Button>
            </Link>
          </Grid>
        </Grid>
        <Typography variant="h4" style={{
          marginTop: '1em'
        }}>History</Typography>
        <Divider style={{
          marginBottom: '1em'
        }}></Divider>
        {/* <Typography>{JSON.stringify(userAudit)}</Typography> */}
        <BasicTable rows={userAudit}></BasicTable>
    </Box>
  );
};
export default Account;