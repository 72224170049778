import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const Code = ({children}) => {
    console.log(children)
    return (
      <SyntaxHighlighter useInlineStyles={true} language="python" style={docco}>
        {children}
      </SyntaxHighlighter>
    )
  }

function DetectingBotsWithPython() {
    const loginHandler = `from flask import Flask

app = Flask(__name__)

@app.route('/login', methods=["POST"])
def login_user():
    data = request.get_json()
    if data["username"] != "andrew" and data["password"] != "andrewsPassword":
        return "invalid username or password"
    return 'Login successful'

app.run(host='0.0.0.0', port=81)`

    const loginHandlerWithIPDetective = `from flask import Flask
import requests
import os

app = Flask(__name__)

def check_ip(ip):
    url = "https://api.ipdetective.io/ip/" + ip
    header = {
        "x-api-key": os.environ.get("IPDETECTIVE_API_KEY")
    }
    resp = requests.get(url, header=header)
    return resp.json()


@app.route('/login', methods=["POST"])
def login_user():
    data = request.get_json()
    if data["username"] != "andrew" and data["password"] != "andrewsPassword":
        return "invalid username or password"
    client_ip = request.remote_addr
    if check_ip(client_ip)["bot"] is True:
        return "client IP address detected as a non-human user by IPDetective"
    return 'Login successful'

app.run(host='0.0.0.0', port=81)`


    return (<Box>
        <Typography>
            This blog post will go over a simple implementation of using IPDetective to help detect or prevent non-human users from using a login REST endpoint.
            First thing first, lets show what the current python flask REST endpoint looks like.
        </Typography>
        <Code>
            {loginHandler}
        </Code>
        <Typography>
            This endpoint is rather simple. 
            The endpoint function just validates that the username provided is 'andrew' and the password is 'andrewsPassword'.
            If this criteria is met then a 'Login successful' message is sent to the client.
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            Now lets get the clients IP address and leverage the IPDetective REST API to see if the client's IP address is a bot (non-human user) or not.
        </Typography>
        <Code>
            {loginHandlerWithIPDetective}
        </Code>
        <Typography>
            In the function above, the 'request' library is used to make a HTTP request to the IPDetective REST API. 
            Also the IPDetective api key is stored as an environment variable 'IPDETECTIVE_API_KEY'.
            The client IP address is obtained using 'request.remote_addr'. 
            Take note that the client IP address may have to be obtained in a different manner if the flask service is deployed behind a proxy.
            Finally we bring this all together to check if the client IP address is a bot or non-human user. 
            If IPDetective detects the client ip address as a bot then the following message is returned to the end user 'client IP address detected as a non-human user by IPDetective'. 
        </Typography>
    </Box>)
}

export default DetectingBotsWithPython