import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const Code = ({children}) => {
    console.log(children)
    return (
      <SyntaxHighlighter useInlineStyles={true} language="go" style={docco}>
        {children}
      </SyntaxHighlighter>
    )
  }

function DetectingBotsWithGolang() {
    const loginHandler = `type User struct {
    Username string \`json:"user"\`
    Password string \`json:"password"\`
}

func LoginUser(w http.ResponseWriter, r *http.Request) {
    user := User{}
    err := json.NewDecoder(r.Body).Decode(&user)
    if err != nil {
        panic("invalid user provided")
    }
    if user.Username != "andrew" || user.Password != "andrewsPassword" {
        panic("invalid username or password")
    }
    w.WriteHeader(200)
}`

    const loginHandlerWithIPDetective = `type User struct {
    Username string \`json:"user"\`
    Password string \`json:"password"\`
}

type IPCheck struct {
    IP  string \`json:"ip"\`
    Bot bool   \`json:"bot"\`
}

func checkIP(ip string) (IPCheck, error) {
	url := "https://api.ipdetective.io/ip/" + ip
	method := "GET"
	req, err := http.NewRequest(method, url, nil)
	req.Header.Add("x-api-key", os.Getenv("IPDETECTIVE_API_KEY"))
	result := IPCheck{}
	if err != nil {
		return result, err
	}
	client := http.DefaultClient
	res, err := client.Do(req)
	if err != nil {
		return result, err
	}
	defer res.Body.Close()
	body, err := ioutil.ReadAll(res.Body)
	if err != nil {
		return result, err
	}
	err = json.Unmarshal(body, &result)
	return result, err
}

func LoginUser(w http.ResponseWriter, r *http.Request) {
    user := User{}
    err := json.NewDecoder(r.Body).Decode(&user)
    if err != nil {
        panic("invalid user provided")
    }
    if user.Username != "andrew" || user.Password != "andrewsPassword" {
        panic("invalid username or password")
    }
    ip,_,_ := net.SplitHostPort(r.RemoteAddr)
    ipdetectiveResult, _ := checkIP(ip)
    if ipdetectiveResult.Bot {
        panic("ip address detected as bot using IPDetective")
    }
    w.WriteHeader(200)
}`


    return (<Box>
        <Typography>
            This blog post will go over a simple implementation of using IPDetective to help detect or prevent non-human users from using a login REST endpoint.
            First thing first, lets show what the current REST endpoint looks like.
        </Typography>
        <Code>
            {loginHandler}
        </Code>
        <Typography>
            As you can see this endpoint really does not do much.
            It takes in the json body and checks if the username field is 'andrew' and the password field is 'andrewsPassword'.
            If the provided request meets those requirements then a 200 status code is returned. If not, then panic which will return a 503 to the client. 
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            Now lets get the clients IP address and leverage the IPDetective REST API to see if the client's IP address is a bot (non-human user) or not.
        </Typography>
        <Code>
            {loginHandlerWithIPDetective}
        </Code>
        <Typography>
            I created a private function called 'checkIP' that performs a HTTP request to IPDetective and return the response. 
            In my handler (LoginUser function) I get the clients IP address by using 'r.RemoteAddr' and parse out the port using 'net.SplitHostPort' function.
            I then pass this parsed IP address to the 'checkIP' function. 
            Finally I check the result to see if bot is true and if so I panic with 'ip address detected as bot using IPDetective'.
        </Typography>
    </Box>)
}

export default DetectingBotsWithGolang