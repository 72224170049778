import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

function CheckIfIPAddressIsAVPN() {
    return (<Box>
        <Typography>
            VPN services are created every day. 
            Public VPN services IP addresses can be changed on a daily basis making it difficult to identify specific IP addresses to a VPN service. 
            Detecting IP addresses for private VPN services is even more difficult since the service is not adversised and only used by a small subset of users and is not open to the public. 
            However some small assumptions can be made to help identify if an IP address originates from a VPN.
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            IPDetective solves this issue by first identifying datacenters that exist and the IP ranges that make up these datacenters. 
            Most VPN services use these datacenters to host their VPN service allowing IPDetective to mark these IP addresses as bots/non-humans. 
            This does not however get all of the VPNs that run on dedicated hardware or smaller datacenters that may be difficult to identify. 
            So extra steps can be made like ports scanning and service identification to detect VPN services that are not using popular datacenters. 
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            IPDetective can help elleviate bot abuse and detect VPNs of website users. 
            A free trial is available up to 1,000 requests a day <Link href="/login">sign up here</Link>
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            If you have free trial and need to integrate into your application check the blog posts below:
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            <Link href="/blog/detecting-bots-with-golang">Using golang and mux</Link>
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            <Link href="/blog/detecting-bots-with-python">Using python and flask</Link>
            
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            <Link href="/blog/detecting-bots-with-javascript">Using javascript and express</Link>
            
        </Typography>
    </Box>)
}

export default CheckIfIPAddressIsAVPN