import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { Link } from '@mui/material';
export default function FAQ({pageConfig}) {
  const faqs = [
    {
      title: "How many requests can I make per day?",
      info: function() {
        return (
        <Typography>
          The free trial allows you to make 1,000 requests per day. 
          If you need to increase this limit please see <Link href="/pricing">pricing</Link>
        </Typography>)
      },
    },
    {
      title: "Can I check multiple IP addresses in one request?",
      info: function() {
        return (
        <Typography>
          Yes, check out the <Link href="/api#get_ips">bulk IP</Link> endpoint to check multiple IP addresses
        </Typography>)
      },
    },
    {
      title: "I have hit my API limit. How do I increase my limit?",
      info: function() {
        return (
        <Typography>
          If you are interested in increasing your API limit please see the <Link href="/pricing">pricing plans</Link> and select one that meets your needs
        </Typography>)
      },
    },
    {
      title: "Where do the IP addresses come from?",
      info: function() {
        return (
        <Typography>
          They come from varying sources across the internet. We track over 1000 seperate ASNs that offer datacenters, VPN, bot or proxy services. We scrape data from public lists, official cloud providers, botnet lists, proxy lists and more.
        </Typography>)
      },
    },
    {
      title: "Does IPDetective support ipv6 addresses?",
      info: function() {
        return (
        <Typography>
          No, currently IPDetective does not support ipv6 addresses. However this feature is on the road map
        </Typography>)
      },
    }
  ]
  return (
    <Box>
      <Typography variant="h4">FAQ</Typography>
      <Box  style={{
        textAlign: 'left'
      }}>
        {faqs.map((faq, i) => (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${i}a-content`}
              id={`panel${i}a-header`}
            >
              <Typography>{faq.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <faq.info></faq.info>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}