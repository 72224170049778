import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material';

const Code = ({children}) => {
    console.log(children)
    return (
      <SyntaxHighlighter useInlineStyles={true} language="bash" style={docco}>
        {children}
      </SyntaxHighlighter>
    )
  }

function IPDetectiveCommandLineInterface() {
    const code = `cat /var/log/nginx/access.log | awk '{print $1}' | sort | uniq | ipd -csv > unique_vistors.csv`


    return (<Box>
        <Typography>
            IPDetective has an easy to use command line interface thats easily allows you to get IP address information directly from the command line.
            This blog post will go over how to install and use the 'ipd' command line utility.
        </Typography>
        <br/>
        <Typography variant='h4'>
            Installation
        </Typography>
        <Typography>
            Run the curl command below. This will install the 'ipd' binary and place it in the `/usr/local/bin` directory.
        </Typography>
        <Code>
            curl -s "https://raw.githubusercontent.com/AndrewCopeland/ipd/refs/heads/main/install.sh" | bash
        </Code>
        <Typography>
            If you are using a windows device and would like to install the command line utility, you can download the .exe file in the <Link href="https://github.com/AndrewCopeland/ipd/releases">releases section</Link> of the github.
        </Typography>
        <br/>
        <Typography variant='h4'>
            Usage
        </Typography>
        <Typography>
            Get my current machines IP address:
        </Typography>
        <Code>
            ipd
        </Code>
        <Typography>
            Get other machines IP address:
        </Typography>
        <Code>
            ipd 8.8.8.8
        </Code>
        <Typography>
            Get other machines IP address in JSON format:
        </Typography>
        <Code>
            ipd -json 8.8.8.8
        </Code>
        <Typography>
            Get other machines IP address in CSV format:
        </Typography>
        <Code>
            ipd -csv 8.8.8.8
        </Code>
        <Typography>
            Get all unique nginx vistors and output to CSV
        </Typography>
        <Code>
            {code}
        </Code>
        <Typography>
            The source code can be found on the <Link href="https://github.com/AndrewCopeland/ipd">github here</Link>.
        </Typography>
    </Box>)
}

export default IPDetectiveCommandLineInterface