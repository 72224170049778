import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

function MesaIntegration() {
    return (<Box>
        <Typography>
            We're excited to announce that IPDetective is now integrated into Mesa, the ecommerce automation platform. This integration allows ecommerce businesses to quickly and easily analyze website visitor data for potential fraud activity, protecting their customers and improving the performance of their online store.
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            By using IPDetective as part of automated workflows, ecommerce businesses can save time and resources, while improving the accuracy and consistency of their fraud prevention efforts. With Mesa, you can automate the analysis of visitor data, ensuring that potential fraud activity is detected and addressed promptly. This allows businesses to focus on other important tasks such as customer service or product development.
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            The integration of IPDetective into Mesa also provides valuable insights into website traffic, including the location of website visitors and the type of device being used. This information can help businesses optimize their online store and improve the customer experience.
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            We're thrilled to offer this integration to Mesa users, and we believe that it will be a valuable addition to any ecommerce business looking to protect their customers and improve the performance of their online store.
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            To learn more about how Mesa and IPDetective can help your ecommerce business, <Link href="https://www.getmesa.com/apps/ipdetective">visit the MESA website</Link>.
        </Typography>
    </Box>)
}

export default MesaIntegration