import DetectingBotsWithGolang from './component/blog/DetectingBotsWithGolang';
import DetectingBotsWithPython from './component/blog/DetectingBotsWithPython';
import DetectingBotsWithJavascript from './component/blog/DetectingBotsWithJavascript';
import CheckIfIPAddressIsAVPN from './component/blog/CheckIfIPAddressIsAVPN';
import ProtectingWebsiteFromSpamBots from './component/blog/ProtectingWebsiteFromSpamBots'
import CaptchasImpactWebsiteUserExperience from './component/blog/CaptchasImpactWebsiteUserExperience'
import MesaIntegration from './component/blog/MesaIntegration'
import RetrevingUsersIPAddressInLarvel from './component/blog/RetrevingUsersIPAddressInLarvel'
import IPDetectivePythonClientLibrary from './component/blog/IPDetectivePythonClientLibrary'
import RapidAPISupport from './component/blog/RapidAPISupport'
import IPDetectiveGoClientLibrary from './component/blog/IPDetectiveGoClientLibrary'
import IPDetectiveCommandLineInterface from './component/blog/IPDetectiveCommandLineInterface'

import Search from './component/Search';

const { Typography, Link } = require("@mui/material")
const { default: EmailDetectiveLogo } = require("./component/EmailDetectiveLogo")
const { default: Logo } = require("./component/Logo")



const API_URL = "https://api.ipdetective.io"
// const API_URL = "http://localhost:2022"


// const PRIMARY_COLOR = "#3fa34d"
const PRIMARY_COLOR = "#1976d2"
// exports.PRIMARY_COLOR = PRIMARY_COLOR

// const BACKGROUND_COLOR = '#fbfbfb'
// exports.BACKGROUND_COLOR = BACKGROUND_COLOR

// const DEFAULT_LOGO = EmailDetectiveLogo
const DEFAULT_LOGO = Logo
// exports.DEFAULT_LOGO = DEFAULT_LOGO
 
// api docs information
const jsonResult = `$ curl -H 'x-api-key: api-key-here' https://api.ipdetective.io/ip/8.8.8.8
{
  "ip": "8.8.8.8",
  "bot": true
}`
const jsonResultInfo = `$ curl -H 'x-api-key: api-key-here' https://api.ipdetective.io/ip/8.8.8.8?info=true
{
  "ip": "8.8.8.8",
  "bot": true,
  "type": "datacenter",
  "asn": 15169,
  "asn_description": "GOOGLE",
  "country_code": "US",
  "country_name": "United States of America"
}`
const myIpResult = `$ curl -H 'x-api-key: api-key-here' https://api.ipdetective.io/ip
{
  "ip":"72.93.93.101",
  "bot":false
}`
const bulkJsonRequest = `$ curl -H 'x-api-key: api-key-here' "https://api.ipdetective.io/ip" --data '["8.8.8.8", "192.168.1.1", "185.5.4.1"]'
[
  {
    "ip":"8.8.8.8",
    "bot":true
  },
  {
    "ip":"192.168.1.1",
    "bot":false
  },
  {
    "ip":"185.5.4.1",
    "bot":false
  }
]`
const userResult = `$ curl -H 'x-api-key: api-key-here' "https://api.ipdetective.io/usage"
{
  "request_limit":1000,
  "lookup_limit":10000,
  "request_hits":4,
  "lookup_hits":4
}`

const codeDocsConfig = [
  {
    id: "my_ip",
    title: "My IP",
    subTitle: "Look up my ip. To get information like country and ASN use the 'info=true' query parameter.",
    code: myIpResult,
  },
  {
    id: "get_ip",
    title: "Single IP Lookup",
    subTitle: "Look up one specific IP address",
    code: jsonResult
  },
  {
    id: "get_ip_info",
    title: "Single IP Lookup with Info",
    subTitle: "Look up one specific IP address with info. The 'type' field can be the value of datacenter, bot, vpn, proxy or unknown.",
    code: jsonResultInfo
  },
  {
    id: "get_ips",
    title: "Bulk/Batch IP Lookup",
    subTitle: "Look up multiple IP addresses in one request. Maximum amount of 1,000 IPs per request. This endpoint also supports the 'info=true' query parameter.",
    code: bulkJsonRequest
  },
  {
    id: "usage",
    title: "Usage",
    subTitle: "Look up current API usage",
    code: userResult
  }
]

// pricing information
const monthlyTiers = [
    {
        title: 'Basic',
        price: '25',
        occurence: 'mo',
        description: [
        '30,000 daily queries',
        'Email support',
        ],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        productId: 793977,
        message: "Upgrade to basic plan"
    },
    {
        title: 'Premium',
        subheader: 'Most popular',
        price: '50',
        occurence: 'mo',
        description: [
        '80,000 daily queries',
        'Email support',
        ],
        buttonText: 'Get started',
        buttonVariant: 'contained',
        productId: 799114,
        message: "Upgrade to premium plan"
    },
    {
        title: 'Enterprise',
        price: '100',
        occurence: 'mo',
        description: [
        '200,000 daily queries',
        'Email & Phone support',
        ],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        productId: 799115,
        message: "Upgrade to enterprise plan"
    },
];

const annualTiers = [
    {
        title: 'Basic',
        price: '275',
        occurence: 'yr',
        description: [
        '30,000 daily requests',
        'Email support',
        'Save $25'
        ],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        productId: 810281,
        message: "Upgrade to basic plan"
    },
    {
        title: 'Premium',
        subheader: 'Most popular',
        price: '500',
        occurence: 'yr',
        description: [
        '80,000 daily requests',
        'Email support',
        'Save $100'
        ],
        buttonText: 'Get started',
        buttonVariant: 'contained',
        productId: 810282,
        message: "Upgrade to premium plan"
    },
    {
        title: 'Enterprise',
        price: '1000',
        occurence: 'yr',
        description: [
        '200,000 daily requests',
        'Email & Phone support',
        'Save $200'
        ],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
        productId: 810283,
        message: "Upgrade to enterprise plan"
    },
];

// blog config
const blogPostMapping = {
    "ipdetective-command-line-utility": {
      id: "ipdetective-command-line-utility",
      image: "/bash.png",
      date: "11/12/2024",
      width: 120,
      height: 80,
      title: "ipd - The IPDetective command line utlity",
      subTitle: "Retrieve IP information with bash",
      body: <IPDetectiveCommandLineInterface/>,
    },
    "ipdetective-go-client-library": {
      id: "ipdetective-go-client-library",
      image: "/golang-logo.png",
      date: "11/09/2024",
      width: 120,
      height: 50,
      title: "Get users IP address information using go client library",
      subTitle: "Retrieve users IP address information using go",
      body: <IPDetectiveGoClientLibrary/>
   },
    "ipdetective-python-client-library": {
      id: "ipdetective-python-client-library",
      image: "/python-logo.png",
      date: "03/27/2024",
      width: 80,
      height: 80,
      title: "Get users IP address information using python",
      subTitle: "Retrieve users IP address information using Python with pip",
      body: <IPDetectivePythonClientLibrary/>
   },
   "ipdetective-available-on-rapidapi": {
      id: "ipdetective-available-on-rapidapi",
      image: "rapidapi.png",
      date: "04/01/2024",
      width: 80,
      height: 80,
      title: "Access IPDetective on RapidAPI",
      subTitle: "Leverage RapidAPI to interface with the IPDetective API",
      body: <RapidAPISupport/>
    },
    "larvel-10-get-users-ip-address": {
      id: "larvel-10-get-users-ip-address",
      image: "larvel10.svg",
      date: "03/19/2024",
      width: 80,
      height: 80,
      title: "Get users IP address in Larvel 10",
      subTitle: "Easily retrieve users IP address in Larvel 10 and get IP information for free",
      body: <RetrevingUsersIPAddressInLarvel/>
    },
    "mesa-integration": {
      id: "mesa-integration",
      image: "mesa-logo.svg",
      date: "04/23/2023",
      width: 80,
      height: 80,
      title: "Protect your Shopify store against fraudulent orders",
      subTitle: "Mesa and IPDetective join forces to protect your shopify store against fraud",
      body: <MesaIntegration/>
    },
    "captchas-impact-website-user-experience": {
        id: "captchas-impact-website-user-experience",
        image: "/captcha_400x400.png",
        date: "02/21/2023",
        width: 80,
        height: 80,
        title: "How CAPTCHA impacts your website’s user experience",
        subTitle: "Pros and cons of CAPTCHAs on user experience",
        body: <CaptchasImpactWebsiteUserExperience/>,
    },
    "protecting-website-from-spam-bots": {
        id: "protecting-website-from-spam-bots",
        image: "/robot.jpg",
        date: "12/21/2022",
        width: 70,
        height: 80,
        title: "Protecting your website from spam bots",
        subTitle: "Multiple layers of protection are required to mitigate spam bots",
        body: <ProtectingWebsiteFromSpamBots/>,
    },
    "check-if-ip-address-is-a-vpn": {
        id: "check-if-ip-address-is-a-vpn",
        title: "How to check if an IP address is from a VPN",
        subTitle: "Detecting client IP addresses as VPNs using javascript, golang or python",
        image: "/vpn-icon.png",
        date: "12/02/2022",
        width: 70,
        height: 70,
        body: <CheckIfIPAddressIsAVPN/>,
    },
    "detecting-bots-with-golang": {
        id: "detecting-bots-with-golang",
        image: "/golang-logo.png",
        date: "11/03/2022",
        width: 120,
        height: 50,
        title: "Golang - Detecting bots and non-human users",
        subTitle: "Get client IP address and detect non-human users with golang and mux",
        body: <DetectingBotsWithGolang/>,
    },
    "detecting-bots-with-python": {
        id: "detecting-bots-with-python",
        image: "/python-logo.png",
        date: "11/03/2022",
        width: 70,
        height: 70,
        title: "Python - Detecting bots and non-human users",
        subTitle: "Get client IP address and detect non-human users with python and flask",
        body: <DetectingBotsWithPython/>,
    },
    "detecting-bots-with-javascript": {
        id: "detecting-bots-with-javascript",
        image: "/node-js-logo.png",
        date: "11/03/2022",
        width: 70,
        height: 70,
        title: "Javascript - Detecting bots and non-human users",
        subTitle: "Get client IP address and detect non-human users with javascript and express",
        body: <DetectingBotsWithJavascript/>,
    },
}

// changelog information
const changeLogs = [
    {
      "title": "ipd - official IPDetective command line utility",
      "date": "11/12/2024",
      "tag": "new",
      "paragraphs": [
        "IPDetective now has an official command line utility.",
        "Supports linux, mac and windows operating systems.",
        "Get the power of IPDetective at your fingertips with 'ipd'."
      ]
    },
    {
      "title": "Go client library for IPDetective",
      "date": "11/09/2024",
      "tag": "new",
      "paragraphs": [
        "Easily leverage the IPDetective API using the go client library.",
      ]
    },
    {
      "title": "Python client library for IPDetective",
      "date": "03/27/2024",
      "tag": "new",
      "paragraphs": [
        "Easily leverage the IPDetective API using the python client library from pip.",
      ]
    },
    {
      "title": "IPDetective available on RapidAPI",
      "date": "04/01/2024",
      "tag": "new",
      "paragraphs": [
        "IPDetective is now available to use via RapidAPI.",
      ]
    },
    {
      "title": "API key authentication in UI",
      "date": "03/19/2024",
      "tag": "new",
      "paragraphs": [
        "To allow easier access to the UI across individuals or an organization API key authentication has been implemented in the UI.",
        "To use this service go to the Login page and enter your API key into the input field and click Sign In."
      ]
    },
    {
      "title": "Resolve login issue with legacy users",
      "date": "03/19/2024",
      "tag": "fix",
      "paragraphs": [
        "A bug existed that made legacy users unable to login to the UI.",
        "Legacy users are classified as users who registered within the first 4 months of IPDetective."
      ]
    },
    {
      "title": "Ability to update and cancel subscription",
      "date": "07/19/2023",
      "tag": "improvement",
      "paragraphs": [
        "A section on the Account page has been created called 'Management'. This section allows you to easily update or cancel a current subscription.",
        "Previously a support ticket would have to be created on the Contact page to update or cancel a subscription."
      ]
    },
    {
      "title": "MESA | Shopify automation platform integration",
      "date": "04/22/2023",
      "tag": "new",
      "paragraphs": [
        "MESA Shopify automation platform with IPDetective allows you to mitigate fraudulect e-commerce orders.",
      ]
    },
    {
        "title": "Added ASN lookup page",
        "date": "03/11/2023",
        "tag": "new",
        "paragraphs": [
          "When performing an IP search you can now look into more information about the ASN by clicking the ASN number in the search results. This new page will list out all of the CIDRs associated with this ASN.",
        ]
    },
    {
        "title": "Improve bulk endpoint performance",
        "date": "03/02/2023",
        "tag": "improvement",
        "paragraphs": [
          "The bulk/batch endpoint has been improved 10x in performance.",
          "Previously this endpoint would process 1,000 ip queries in about 60 seconds. With this enhancement this endpoint should now perform 1,000 ip queries in about 6 seconds."
        ]
    },
    {
      "title": "Resolved timeout issue",
      "date": "02/23/2023",
      "tag": "fix",
      "paragraphs": [
        "During large batch IP lookups Nginx would timeout after 60s resulting in a 504 being returned to the client.",
        "This timeout parameter has been now raised to 180 seconds."
      ]
    },
    {
      "title": "Added Google Authentication",
      "date": "02/02/2023",
      "tag": "new",
      "paragraphs": [
        "Google login has been added and can be accessed by simply going to the login page. All previous gmail accounts can now login either via email login or by google sign in.",
      ]
    },
]


const PAGE_CONFIG = {
    home: {
        summary: () => (
            <Typography>
                IPDetective is an API that focuses on bot and non-human IP detection to quickly identify if an IP address 
                comes from a datacenter, VPN or proxy. 
                You can signup to the free API by simply <Link href="/login">signing in</Link> to access your API key.
                IPDetective tracks over 1000 ASNs and detects over 250 million IP addresses as non-human users from +100 different 
                origins, ranging from data centers, botnets, proxies and vpns. 
            </Typography>
        ),
        intro_video: "Nx8yPGO9m1M",
        free_summary: "IPDetective is free to use up to 1,000 requests per day. Commercial plans are available for those with greater needs.",
        fast_summary: "IPDetective focuses on speed. IP queries should be fullfilled as quickly as possible. Bot detection should not impact application performance.",
        simple_summary: "APIs are simplistic, allowing you to detect bots from any application or programming language",
        about_us_summary: "IPDetective was created as an internal solution for various projects and has now been opened to the public. We will continuously scrape the internet to give its customers the most up-to-date ip bot detection service.",
        customers: [
          {
            image_path: "/the-shoppad-logo.svg",
            width: "300px",
            height: "200px",
            background: "white",
            title: "The Shoppad | Helping Ecommerce Business Grow"
          },
          {
            image_path: "/social-blade-logo.webp",
            width: "300px",
            height: "200px",
            background: "black",
            title: "Social Blade"
          },
          {
            image_path: "/mesa-logo.svg",
            width: "300px",
            height: "200px",
            background: "white",
            title: "MESA | A shopify e-commererce automation platform"
          }
        ]
      },
    api: {
        code_docs: codeDocsConfig,
        subtitle: "If the IP address request response contains bot = true then the IP address is most likely a bot.",
        additional_information: `
            IPDetective will continue to detect more IP addresses from datacenters, VPNs and proxies. 
            We strive to help alleviate fraud, video game botting and other forms of malicous activies. 
            We have nothing against proxies and vpns as they offer privacy and security, 
            however bot abuse can seriously impact consumer applications and mitigation practices must be 
            used to create high quality consumer facing applications.`
    },
    pricing: {
        monthly_tiers: monthlyTiers,
        annual_tiers: annualTiers,
        sign_in_for_free: "Sign in to try IPDetective for FREE"
    },
    faq: [
        {
          title: "How many requests can I make per day?",
          info: function() {
            return (
            <Typography>
              The free trial allows you to make 1,000 requests per day. 
              If you need to increase this limit please see <Link href="/pricing">pricing</Link>
            </Typography>)
          },
        },
        {
          title: "Can I check multiple IP addresses in one request?",
          info: function() {
            return (
            <Typography>
              Yes, check out the <Link href="/api#get_ips">bulk IP</Link> endpoint to check multiple IP addresses
            </Typography>)
          },
        },
        {
          title: "I have hit my API limit. How do I increase my limit?",
          info: function() {
            return (
            <Typography>
              If you are interested in increasing your API limit please see the <Link href="/pricing">pricing plans</Link> and select one that meets your needs
            </Typography>)
          },
        },
        {
          title: "Where do the IP addresses come from?",
          info: function() {
            return (
            <Typography>
              They come from varying sources across the internet. We track over 1000 seperate ASNs that offer datacenters, VPN, bot or proxy services. We scrape data from public lists, official cloud providers, botnet lists, proxy lists and more.
            </Typography>)
          },
        },
        {
          title: "Does IPDetective support ipv6 addresses?",
          info: function() {
            return (
            <Typography>
              No, currently IPDetective does not support ipv6 addresses. However this feature is on the road map
            </Typography>)
          },
        }
    ],
    contact: {
        contact_subjects: [
            {
              value: "general",
              text: "General Contact"
            },
            {
              value: "upgrade",
              text: "Upgrade Plan"
            },
            {
              value: "inaccuracies",
              text: "Invalid results or errors"
            },
            {
              value: "billing",
              text: "Billing"
            },
            {
              value: "security",
              text: "Security/Bug Report"
            },
            {
              value: "gratitude",
              text: "Thanks for IPDetective"
            },
          ]
    },
    search: {
        // the search page is rather customizable since how ips are handled is different than emails
        component: Search
    },
    blog: {
        blogs: blogPostMapping,
    },
    login: {
        subtitle: "Enter your email below to login/register to IPDetective."
    },
    changelog: {
        change_logs: changeLogs
    }
}

const TRACKING_CODE = 'G-BNQ6QNGBFH'

export {PAGE_CONFIG, DEFAULT_LOGO, PRIMARY_COLOR, API_URL, TRACKING_CODE}