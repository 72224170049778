import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Alert, Link, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import * as config from '../config'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Code = ({children}) => {
  console.log(children)
  return (
    <SyntaxHighlighter language="json" style={docco}>
      {JSON.stringify(children, null, 2)}
    </SyntaxHighlighter>
  )
}

const SearchResult = ({searchResult, setReportOpen}) => {
  console.log(searchResult)
  const bold = {
    fontWeight: 'bold'
  }
  const handleOpen = () => {
    console.log("Should open report")
    setReportOpen(true)
  }
  return (
    <Box style={{
      textAlign: 'left'
    }}>
        <Code>{searchResult}</Code>
    </Box>
  )
}

const SearchAsnNumber = ({user}) => {
  const [open, setOpen] = React.useState(false)
  const [reportOpen, setReportOpen] = React.useState(false)
  const [alertVariant, setAlertVariant] = React.useState(null)
  const [alertMessage, setAlertMessage] = React.useState("")
  const [searchResult, setSearchResult] = React.useState({}) 

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  console.log(`THE ASN! ${params.asn}` )
  const [asn, setAsn] = React.useState(params.asn)

  React.useEffect(() => {
    fetch(config.API_URL + `/asn/${params.asn}`, {
      headers: {
        "x-api-key": user.api_key
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        if ('error' in body) {
          console.log(body)
          setOpen(true)
          return
        }
        document.getElementById("search-ip").value = body.as_number

        setAlertVariant('info')
        setAlertMessage(<SearchResult searchResult={body} setReportOpen={setReportOpen}></SearchResult>)
        
      })
    })
  }, [])

  return (
    <Box>
        <Typography variant="h4">AS number lookup</Typography>
        <Container maxWidth="sm">
        <Box style={{
          display:'inline-flex',
          marginTop: '1em'
        }}>
          <TextField
            label="ASN"
            id="search-ip"
            defaultValue={"15169"}
          ></TextField>
          <Button
            variant="contained"
            size="small"
            style={{
              marginLeft: '.5em'
            }}
            onClick={() => {
              setAsn("")
              const asn = document.getElementById("search-ip").value
              window.location.replace("/search-asn?asn="+asn)
            }}
          >Search</Button>
        </Box>
        <Box style={{
          marginTop: '1em'
        }}>
          { alertVariant !== null &&
            <Alert severity={alertVariant}>{alertMessage}</Alert>
          }
        </Box>
        </Container>

    </Box>
  );
};
export default SearchAsnNumber;