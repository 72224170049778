import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import LinkIcon from '@mui/icons-material/Link';
import { Button, IconButton } from '@mui/material';

const Code = ({children}) => {
  console.log(children)
  return (
    <SyntaxHighlighter language="bash" style={docco}>
      {children}
    </SyntaxHighlighter>
  )
}

const CodeDoc = ({id, title, subTitle, code}) => {
  return (
    <Box>
      <Box style={{
        display: 'flex',
        marginTop: '.5em'}}>
        <Typography variant="h6" id={id}>{title} <IconButton style={{marginLeft: '-.25em'}} onClick={() => {
          window.location.replace(`/api#${id}`)
        }}><LinkIcon fontSize='small'/></IconButton></Typography>
        
      </Box>
      <Typography>{subTitle}</Typography>
      <Code>
          {code}
      </Code>
      <Divider></Divider>
    </Box>
  )
}

// Got this from here: https://stackoverflow.com/questions/40280369/use-anchors-with-react-router
// This will 
function hashLinkScroll() {
  const { hash } = window.location;
  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }, 0);
  }
}



const API = ({config}) => {
  hashLinkScroll()
  const pageConfig = config.PAGE_CONFIG.api
  let codeDocs = []
  for (const i in pageConfig.code_docs) {
    const doc = pageConfig.code_docs[i]
    codeDocs.push(<CodeDoc
      id={doc.id}
      title={doc.title}
      subTitle={doc.subTitle}
      code={doc.code}
    />)
  }

  return (
    <Box style={{
      textAlign: 'left',
      alignContent: 'left'
    }}>
        <Typography variant="h4">Documentation</Typography>
        <Typography>First you must <Link href="/login">login</Link> to get access to your API key.</Typography>
        <Typography/>
        <Typography>{pageConfig.subtitle}</Typography>
        <Typography variant="h4" style={{ marginTop: '1em' }}>Usage</Typography>
        <Box>
          {codeDocs}
        </Box>
        <Typography variant="h4" style={{ marginTop: '1em' }}>Additional Information</Typography>
        <Typography>{pageConfig.additional_information}</Typography>
    </Box>
  );
};
export default API;