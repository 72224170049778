import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

function CaptchasImpactWebsiteUserExperience() {
    return (<Box>
        <Typography>
            Due to the increase of spam bots on the internet, many website owners have resorted to using 
            tools such as CAPTCHA to prevent these bots from using their websites. Anyone reading this article 
            has probably seen that text box with random alphanumeric characters that you must retype to 
            confirm that you are not a robot. Such tests are called CAPTCHA.
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            If you have a website that you suspect has many non-human (bots) visitors, using CAPTCHA is one of the ways you can deal with that problem. But before using this solution, it is important to understand all its benefits and drawbacks. This will help you determine if it is the best solution for your site
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            In this article, I will discuss how using CAPTCHA could impact your website’s user experience. Let’s get started! 
        </Typography>
        <Typography variant='h5' style={{
            marginTop: '1em'
        }}>
            What is CAPTCHA? 
        </Typography>
        <Typography style={{
            marginTop: ''
        }}>
            CAPTCHA is an acronym for Completely Automated Turing test to tell Computers and Humans Apart. Some of the tests used to determine if a website visitor is a human include picture identification and retyping alphanumeric characters. These tests may seem straightforward for humans, but they are extremely complex for computers (robots) to solve. 
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            <Link href="https://www.google.com/recaptcha/about/">reCAPTCHA</Link> is one of the common CAPTCHA systems 
            that anyone can integrate into their website for free. This system was developed by Google to help developers 
            protect their websites and apps from spam and abuse. There is also <Link href="https://hcaptcha.com">hCaptcha</Link> which has the same funtionality 
            but advertises privacy first. 
        </Typography>
        <Typography variant='h5' style={{
            marginTop: '1em'
        }}>
            Benefits of using CAPTCHA on your website
        </Typography>
        <Typography style={{
            fontWeight: 'bold'
        }}>
            Protects your website from spam engagement
        </Typography>
        <Typography style={{
        }}>
            
            If your website allows users to comment on your content or input certain information in forms, using CAPTCHA will prevent bots from participating in such activities. This ensures that all information users share on your site is from real humans, not malicious users or bots. Most of these bots share comments with links to spam websites with the goal of compromising some of your users. 
        </Typography>
        <Typography style={{
            fontWeight: 'bold',
            marginTop: '1em'
        }}>
            Accurate tracking of traffic
        </Typography>
        <Typography style={{
        }}>
            Most traffic tracking tools cannot distinguish between robots and real humans. Using a CAPTCHA system like reCAPTCHA can help you know the number of real users visiting your site. Having accurate information about your traffic makes it easier to plan for your website and how best you can grow it. 
        </Typography>
        <Typography style={{
            fontWeight: 'bold',
            marginTop: '1em'
        }}>
            Minimizes unnecessary consumption of website resources
        </Typography>
        <Typography style={{
        }}>
            Having so many bots visiting and using your website can slow it down, especially if the site is running on limited computing (CPU, RAM, and storage). A CAPTCHA system can help prevent bots from overwhelming your website’s infrastructure. 
        </Typography>
        <Typography variant='h5' style={{
            marginTop: '1em'
        }}>
            Drawbacks of CAPTCHA
        </Typography>
        <Typography style={{
            fontWeight: 'bold',
        }}>
            Most users hate it
        </Typography>
        <Typography style={{
        }}>
            Most internet users want the process of accessing a website and using its services to be as smooth as possible. Any extra barrier that gets in their way can be very frustrating and sometimes cause some to stop using the website immediately. This can potentially affect your conversion rate if you are running an online store. 
        </Typography>
        <Typography style={{
            fontWeight: 'bold',
            marginTop: '1em'
        }}>
            They are not 100% foolproof.
        </Typography>
        <Typography style={{
        }}>
            Despite being very effective at fighting bots, CAPTCHA systems are not 100% perfect. Some advanced bots can still go past some CAPTCHA systems, especially text-based ones. That is why using the image identification CAPTCHA system is recommended. 
        </Typography>
        <Typography style={{
            fontWeight: 'bold',
            marginTop: '1em'
        }}>
            They are not accessible.
        </Typography>
        <Typography style={{
        }}>
            One of the major criticisms about CAPTCHA systems is that they don’t put into consideration people with disabilities. For instance, people with vision problems may not be able to identify some twisted letters that some CAPTCHA systems use. However, this problem has partly been addressed by introducing audio-based CAPTCHA systems. 
        </Typography>
        <Typography variant='h5' style={{
            marginTop: '1em'
        }}>
            Final thoughts
        </Typography>
        <Typography style={{
        }}>
            As we have discussed, using CAPTCHA systems on your website has several crucial benefits. However, when choosing which CAPTCHA system to use, it is always best to consider the user experience of your site’s visitors. For instance, consider providing several CAPTCHA options (for example, text and audio) to ensure all your website visitors, including those with disabilities, are not left out. 
        </Typography>
        <Typography style={{
            marginTop: '1em'
        }}>
            It is also recommended to attach a short message explaining to users why they have to take that extra step if they want to participate in certain activities on your site. 
        </Typography>
    </Box>)
}

export default CaptchasImpactWebsiteUserExperience