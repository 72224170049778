import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const Code = ({children}) => {
    console.log(children)
    return (
      <SyntaxHighlighter useInlineStyles={true} language="python" style={docco}>
        {children}
      </SyntaxHighlighter>
    )
  }

function IPDetectivePythonClientLibrary() {
    const codeExample = `import os
from ipdetective import IPDetective

def main():
    api_key = os.getenv('IPDETECTIVE_API_KEY')
    ip_client = IPDetective(api_key)
    
    # Get information about a singular IP address
    ip_info = ip_client.GetIpInfo('8.8.8.8')
    print(ip_info)
    # {
    #     'ip': '8.8.8.8', 
    #     'bot': True, 
    #     'type': 'bot', 
    #     'asn': 15169, 
    #     'asn_description': 'GOOGLE', 
    #     'country_code': 'US', 
    #     'country_name': 
    #     'United States of America'
    # }

    # Get information about build IP addresses
    bulk_ip_info = ip_client.GetBulkIpInfo(['8.8.8.8', '1.1.1.1'])
    print(bulk_ip_info)
    # [
    #     {
    #         'ip': '1.1.1.1', 
    #         'bot': True, 
    #         'type': 'bot', 
    #         'asn': 13335, 
    #         'asn_description': 'CLOUDFLARENET', 
    #         'country_code': 'US', 
    #         'country_name': 'United States of America'
    #     }, 
    #     {
    #         'ip': '8.8.8.8', 
    #         'bot': True, 
    #         'type': 'bot', 
    #         'asn': 15169, 
    #         'asn_description': 'GOOGLE', 
    #         'country_code': 'US', 
    #         'country_name': 'United States of America'
    #     }
    # ]

if __name__ == "__main__":
    main()
`


    return (<Box>
        <Typography>
            We are excited to announce the python module for IPDetective. 
            This client library allows you to easily lookup IP address information such as if its a bot, country of origin and ASN number.
            The aim of this client is to make it even easier for our customers and users to interface with the IPDetective API.
        </Typography>
        <Typography>
            First step is to install the IPDetective client using 'pip'.
        </Typography>
        <Code>
            pip install ipdetective
        </Code>
        <Typography>
            Below you will see how to use the Python module for IPDetective.
            You will see an example of getting information about one IP address and a bulk number of IP addresses.
        </Typography>
        <Code>
            {codeExample}
        </Code>
        <Typography>
            Check out <a href="https://pypi.org/project/ipdetective/">PyPi website</a> or the <a href="https://github.com/AndrewCopeland/ipdetective-client-python">GitHub</a>
        </Typography>
    </Box>)
}

export default IPDetectivePythonClientLibrary;