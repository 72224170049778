import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import * as config from '../config'


const Register = ({setUser}) => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  const [email, setEmail] = React.useState("")
  const [severity, setSeverity] = React.useState("info")
  const [alertMessage, setAlertMessage] = React.useState("")
  // if (user.email != "") {
  //   window.location.replace("/account")
  // }
  const handleRegister = () => {
    fetch(config.API_URL + "/newUser/" + params.temp_key, {
      method: "GET"
    }).then(response => {
      response.json().then(body => {
        if ('error' in body && body.error != "") {
          setSeverity('error')
          setAlertMessage(body.error)
          return
        }
        setSeverity('success')
        setAlertMessage("logging in....")
        console.log(body)
        setUser(body)
        localStorage.setItem("user", JSON.stringify(body))
        window.location.replace("/account")
      })
    })
  }
  React.useEffect(() => {
    handleRegister()
  }, [])
  return (
    <Box>
        <Typography variant="h4">Registering....</Typography>
        { severity !== 'info' &&
            <Alert style={{
              marginTop: '1em'
            }} severity={severity}>{alertMessage}</Alert>
        }
    </Box>
  );
};
export default Register;