import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from '@mui/material';

const Code = ({children}) => {
    console.log(children)
    return (
      <SyntaxHighlighter useInlineStyles={true} language="go" style={docco}>
        {children}
      </SyntaxHighlighter>
    )
  }

function IPDetectiveGoClientLibrary() {
    const code = `import (
    "fmt"
    "log"

    "github.com/AndrewCopeland/go-ipdetective"
)

func main() {
    cfg := ipdetective.NewConfiguration()
    cfg.AddDefaultHeader("x-api-key", "<api-key-goes-here>")
    client := ipdetective.NewAPIClient(cfg).DefaultAPI
    ip := "8.8.8.8"
    ipInfo, respHttp, err := client.GetIP(ctx, ip).Info(true).Execute()
    if err != nil {
        log.Fatalf("generic error occured when getting ip info. %s", err)
    }
    if respHttp.StatusCode != 200 {
        log.Fatalf("invalid status code was returned %d", respHttp.StatusCode)
    }

    fmt.Printf("IP:  %s\\n", resp.Ip)
    fmt.Printf("Bot: %t\\n", resp.Bot)
    fmt.Printf("Type: %s\\n", fromPtr(resp.Type))
    fmt.Printf("ASN: %d\\n", fromPtr(resp.Asn))
    fmt.Printf("ASN Desc: %s\\n", fromPtr(resp.AsnDescription))
    fmt.Printf("Country Code: %s\\n", fromPtr(resp.CountryCode))
    fmt.Printf("Country Name: %s\\n", fromPtr(resp.CountryName))
}

func fromPtr[T any](ptr *T) T {
    if ptr == nil {
        var zero T
        return zero
    }
    return *ptr
}`


    return (<Box>
        <Typography>
            This blog post will cover the IPDetective go client library.
            First you will need to install the client library.
        </Typography>
        <Code>
            go get github.com/AndrewCopeland/go-ipdetective
        </Code>
        <Typography>
            Below is an example code of looking up the ip information for '8.8.8.8' with IPDetective.

            It goes over all of the fields returned as well and prints them out.
        </Typography>
        <Code>
            {code}
        </Code>
        <Typography>
            The source code can be found on the <Link href="https://github.com/AndrewCopeland/go-ipdetective">github here</Link>.
        </Typography>
    </Box>)
}

export default IPDetectiveGoClientLibrary