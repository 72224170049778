import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const Code = ({children}) => {
    console.log(children)
    return (
      <SyntaxHighlighter useInlineStyles={true} language="php" style={docco}>
        {children}
      </SyntaxHighlighter>
    )
  }

function RetrevingUsersIPAddressInLarvel() {
    const codeExample = `use GuzzleHttp\\Client;
use Illuminate\\Http\\Request;
public function index(Request $request)
{
    $clientIpAddress = $request->ip();
    // If you are using a proxy or load balancer you may need to use a seperate getClientIp function
    $clientIpAddress = $request->getClientIp();

    // Create a new GuzzleHttp client instance
    $client = new Client();

    // Send http request to get information about the clients IP address
    $response = $client->request('GET', 'https://api.ipdetective.io/ip/' + $clientIpAddress + '?info=true', [
        'headers' => [
            'x-api-key' => 'api-key-here'
        ]
    ]);

    // Get response and parse json
    $body = $response->getBody()->getContents();
    $data = json_decode($body, true);

    // $data will look similar to
    // {
    //     "ip": "8.8.8.8",
    //     "bot": true,
    //     "type": "datacenter",
    //     "asn": 15169,
    //     "asn_description": "GOOGLE",
    //     "country_code": "US",
    //     "country_name": "United States of America"
    // }
}
`


    return (<Box>
        <Typography>
            To get a users IP address using larvel 10 you can implement the following code. 
            In the example below you can see we are fetching the users IP address and then querying IPDetective to get more information about the ip address. 
            Make note that if you are using a proxy or load balancer you will need to use the 'getClientIp()' function instead of 'ip()'.
        </Typography>
        <Code>
            {codeExample}
        </Code>
    </Box>)
}

export default RetrevingUsersIPAddressInLarvel;