import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Alert, Link, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import * as config from '../config'
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SearchToolTip = ({name, description}) => {
  const bold = {
    fontWeight: 'bold',
    cursor: 'pointer'
  }
  return (
    <Tooltip placement="top" title={description}>
      <Typography sx={bold}>{name}:</Typography>
    </Tooltip>
  )
}

const SearchResult = ({searchResult, setReportOpen}) => {
  console.log(searchResult)
  const bold = {
    fontWeight: 'bold'
  }
  const handleOpen = () => {
    console.log("Should open report")
    setReportOpen(true)
  }
  return (
    <Box >
    <Box style={{
      display: 'flex'
    }}>
      <Box width='150px' style={{
        alignItems: 'left',
        textAlign: 'right',
        fontWeight: 'bold',
        marginRight: '.5em'
      }}>
        <SearchToolTip name="IP Address" description="An ipv4 or ipv6 address"/>
        <SearchToolTip name="Bot" description="Will return true if the IP address is a vpn, proxy, datacenter or botnet"/>
        <SearchToolTip name="Type" description="Will return vpn, proxy, datacenter, bot or unknown"/>
        <SearchToolTip name="Country Name" description="Name of the country"/>
        <SearchToolTip name="Country Code" description="The alpha-2 code described in the ISO 3166 international standard"/>
        <SearchToolTip name="ASN" description="An autonomous system (AS) is a collection of connected Internet Protocol (IP) routing prefixes under the control of one or more network operators on behalf of a single administrative entity or domain, that presents a common and clearly defined routing policy to the Internet."/>
        <SearchToolTip name="Description" description="The ASN description"/>
        <SearchToolTip name="Report" description="Report an IP inaccuracy"/>
      </Box>
      <Box width='300px' style={{
        alignItems: 'left',
        textAlign: 'left'
      }}>
        <Typography>{searchResult.ip}</Typography>
        <Typography>{JSON.stringify(searchResult.bot)}</Typography>
        <Typography>{searchResult.type}</Typography>
        <Typography>{searchResult.country_name}</Typography>
        <Typography>{searchResult.country_code}</Typography>
        <Typography><Link href={"/search-asn?asn=" + searchResult.asn}>{searchResult.asn}</Link></Typography>
        <Typography>{searchResult.asn_description}</Typography>
        {/* href={"/contact?subject=inaccuracies&message=The ip address " + searchResult.ip + " is invalid"} */}
        <Link onClick={handleOpen} style={{
          cursor: 'pointer'
        }}><Typography>Report inaccuracies</Typography></Link>
      </Box>
       
    </Box>
      
    </Box>
  )
}

const AlertDialogSlide = ({open, setOpen}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Please login"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Log in with your email to IPDetective and access 1,000 FREE ip queries.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button href={`/login`}>Login</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ReportDialogSlide = ({open, setReportOpen, searchResult, user, ip}) => {
  const [openASNTextField, setOpenASNTextField] = React.useState(false)
  const [openCountryextField, setOpenCountryTextField] = React.useState(false)
  const [openAdditionalTextField, setOpenAdditionalTextField] = React.useState(false)
  const [severity, setSeverity] = React.useState('info')
  const [alertMessage, setAlertMessage] = React.useState("")
  const [isABot, setIsABot] = React.useState(false)
  const [isNotABot, setIsNotABot] = React.useState(false)
  const [incorrectASN, setIncorrectASN] = React.useState(false)
  const [incorrectASNText, setIncorrectASNText] = React.useState(false)
  const [incorrectCountry, setIncorrectCountry] = React.useState(false)
  const [incorrectCountryText, setIncorrectCountryText] = React.useState(false)
  const [additionalText, setAdditionalText] = React.useState(false)
  const [additionalTextText, setAdditionalTextText] = React.useState(false)
  const [internalIP, setInternalIP] = React.useState(ip)

  React.useEffect(() => {
    setInternalIP(ip)
  }, [ip])

  const resetValues = () => {
    setOpenASNTextField(false)
    setOpenCountryTextField(false)
    setOpenAdditionalTextField(false)
    setIsABot(false)
    setIsNotABot(false)
    setIncorrectASN(false)
    setIncorrectASNText(false)
    setIncorrectCountry(false)
    setIncorrectCountryText(false)
    setAdditionalText(false)
    setAdditionalTextText(false)
  }

  const handleIncorrectASNToggle = () => {
    setOpenASNTextField(!openASNTextField)
  }

  const handleIncorrectCountryToggle = () => {
    setOpenCountryTextField(!openCountryextField)
  }

  const handleAdditionalTextFieldToggle = () => {
    setOpenAdditionalTextField(!openAdditionalTextField)
  }
  const handleClose = () => {
    setReportOpen(false);
  };
  const handleSubmit = () => {
    setSeverity('success')
    setAlertMessage('Sending...')
   
    const message = `
IP: ${ip}
Is a bot: ${isABot}
Is not a bot: ${isNotABot}
Incorrect ASN: ${incorrectASN}
Incorrect ASN Value: ${incorrectASNText}
Incorrect Country: ${incorrectCountry}
Incorrect Country Value: ${incorrectCountryText}
Additional information: ${additionalText}
Additional information Value: ${additionalTextText}`

    fetch(config.API_URL + "/contact", {
      method: "POST",
      body: JSON.stringify({
        subject: "inaccuracies",
        email: user.email,
        message: message
      })
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        console.log("meep", body)
        setTimeout(function () {
          setSeverity('info')
          handleClose()
        }, 750);
        if ('error' in body) {
          setAlertMessage(body.error)
          setSeverity('error')
          return
        }

        setAlertMessage("Sent!")
        setSeverity('success')
        resetValues()
      })
    })

    

  }

  const setFormValue = (type, setFunc, preProcess=function(){}) => {
    return (e) => {
      preProcess()
      if (type === "bool") {
        setFunc(e.target.checked)
      } else if (type === "string") {
        setFunc(e.target.value)
      }
      
    }
  }
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle><Typography variant="h6">Report an IP address</Typography></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Typography sx={{ fontWeight: 'bold', marginBottom: '.5em' }}>{ip}</Typography>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={isABot} onChange={setFormValue("bool", setIsABot)}/>} label="Is bot" />
              <FormControlLabel control={<Checkbox checked={isNotABot} onChange={setFormValue("bool", setIsNotABot)}/>} label="Is not bot" />
              <FormControlLabel control={<Checkbox checked={incorrectASN} onChange={setFormValue("bool", setIncorrectASN, handleIncorrectASNToggle)}/>} label="Incorrect ASN" />
              {  openASNTextField && 
                <FormControlLabel control={<TextField onChange={setFormValue("string", setIncorrectASNText)} style={{
                  marginLeft: '2.5em',
                  width: '8em'
                }}/>} label="" />
              }
              <FormControlLabel control={<Checkbox checked={incorrectCountry} onChange={setFormValue("bool", setIncorrectCountry, handleIncorrectCountryToggle)}/>} label="Incorrect Country" />
              {  openCountryextField && 
                <FormControlLabel control={<TextField onChange={setFormValue("string", setIncorrectCountryText)} style={{
                  marginLeft: '2.5em',
                  width: '8em'
                }}/>} label="" />
              }
              <FormControlLabel control={<Checkbox checked={additionalText} onChange={setFormValue("bool", setAdditionalText, handleAdditionalTextFieldToggle)}/>} label="Additional information" />
              {  openAdditionalTextField && 
                <FormControlLabel control={<TextField onChange={setFormValue("string", setAdditionalTextText)} style={{
                  marginLeft: '2.5em',
                  width: '8em'
                }}/>} label="" />
              }
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box>
          { severity !== 'info' &&
              <Alert style={{
                marginTop: '1em'
              }} severity={severity}>{alertMessage}</Alert>
          }
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const Search = ({user}) => {
  const [open, setOpen] = React.useState(false)
  const [reportOpen, setReportOpen] = React.useState(false)
  const [alertVariant, setAlertVariant] = React.useState(null)
  const [alertMessage, setAlertMessage] = React.useState("")
  const [searchResult, setSearchResult] = React.useState({}) 

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  console.log(`THE IP! ${params.ip}` )
  const [ip, setIp] = React.useState(params.ip)

  React.useEffect(() => {
    let ipURL = "" 
    if (params.ip === "" || params.ip === undefined || params.ip === null) {

    } else {
      ipURL = "/" + params.ip
    }
    fetch(config.API_URL + `/ip${ipURL}?info=true`, {
      headers: {
        "x-api-key": user.api_key
      }
    }).then(response => {
      console.log(response)
      response.json().then(body => {
        if ('error' in body) {
          console.log(body)
          setOpen(true)
          return
        }
        document.getElementById("search-ip").value = body.ip
        if (body.bot) {
          setAlertVariant('error')
          setAlertMessage(<SearchResult ip={ip} searchResult={body} setReportOpen={setReportOpen}></SearchResult>)
        } else {
          setAlertVariant('success')
          setIp(body.ip)
          setSearchResult(body)
          setAlertMessage(<SearchResult ip={ip} searchResult={body} setReportOpen={setReportOpen}></SearchResult>)
        }
      })
    })
  }, [])

  return (
    <Box>
        <AlertDialogSlide open={open} setOpen={setOpen}></AlertDialogSlide>
        { ip !== null &&
          <ReportDialogSlide open={reportOpen} setReportOpen={setReportOpen} searchResult={searchResult} user={user} ip={ip}></ReportDialogSlide>
        }
        <Typography variant="h4">IP address lookup</Typography>
        <Container maxWidth="sm">
        <Box style={{
          display:'inline-flex',
          marginTop: '1em'
        }}>
          <TextField
            label="IP"
            id="search-ip"
            defaultValue={"0.0.0.0"}
          ></TextField>
          <Button
            variant="contained"
            size="small"
            style={{
              marginLeft: '.5em'
            }}
            onClick={() => {
              setIp("")
              const ip = document.getElementById("search-ip").value
              window.location.replace("/search?ip="+ip)
            }}
          >Search</Button>
        </Box>
        <Box style={{
          marginTop: '1em'
        }}>
          { alertVariant !== null &&
            <Alert severity={alertVariant}>{alertMessage}</Alert>
          }
        </Box>
        </Container>

    </Box>
  );
};
export default Search;