import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { Container, Grid, Link } from '@mui/material';
import StarIcon from '@mui/icons-material/StarBorder';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = ({open, setOpen, message, productId}) => {
  const handleClose = () => {
    setOpen(false);
    const element = document.getElementsByClassName('checkout-container')[0]
    element.innerHTML = ''
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{message}</DialogTitle>
        <DialogContent>
          <div className='checkout-container'></div>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handlePayment}>Purchase</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const LogInDialogSlide = ({open, setOpen}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Upgrade your plan?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please sign in to upgrade your plan.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button href={`/login`}>Login</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}




const PricingNew = ({user, pageConfig}) => {
  const [open, setOpen] = React.useState(false)
  const [loginOpen, setLoginOpen] = React.useState(false)
  const [message, setMessage] = React.useState("This is a message")
  const [productId, setProductId] = React.useState(1)
  const [occurence, setOccurence] = React.useState("See Annual Pricing") 
  const Paddle = window.Paddle;
  

  const handlePayment = (productId) => {
    console.log("This is the product id: ", productId)
      Paddle.Checkout.open({
        method: 'inline',
        product: productId,
        allowQuantity: false,
        disableLogout: true,
        email: user.email,
        frameInitialHeight: 416,
        frameTarget: 'checkout-container',
        frameStyle: 'width:100%; min-width:312px; background-color: transparent; border: none;' ,
        // successCallback: (data, err) =>{
        //   console.log("Something with paddle is doing something")
        //     console.log(data);
        //     //ADD YOUR EXTRA LOGIC TO SEND TO BACKEND 
        // }
      });
  };

  const handleSeePricing = (productId) => {
    setOpen(true)
    handlePayment(productId)
  }
  const handleSeeLogin = () => {
    setLoginOpen(true)
  }

  const handlePricingFactory = (productId, message) => {
    return () => {
      if (user.email == "") {
        handleSeeLogin()
        return
      }
      setProductId(productId)
      setMessage(message)
      handleSeePricing(productId)
    }
  }

  const handleLogin = () => {
    window.location.replace("/login")
  }


  const [tiers, setTiers] = React.useState(pageConfig.monthly_tiers) 
  const [monthlyTiers, setMonthlyTiers] = React.useState(pageConfig.monthly_tiers) 
  const [annualTiers, setAnnualTiers] = React.useState(pageConfig.monthly_tiers) 

  const handleBillingOccurence = () => {
    if (occurence === "See Annual Pricing") {
      setOccurence("See Monthly Pricing")
      setTiers(annualTiers)
    } else {
      setOccurence("See Annual Pricing")
      setTiers(monthlyTiers)
    }
  }


  const label = { inputProps: { 'aria-label': 'Monthly/Annual Switch' } };

  // const monthlyTiers = pageConfig.monthly_tiers
  // const annualTiers = pageConfig.annual_tiers

  React.useEffect(() => {
    let newTiers = []
    for(const i in pageConfig.monthly_tiers) {
      let tier = pageConfig.monthly_tiers[i]
      tier['handleClick'] =  handlePricingFactory(tier.productId, tier.message)
      newTiers.push(tier)
    }
    console.log("new tiers", newTiers)
    setMonthlyTiers(newTiers)
    setTiers(newTiers)

    newTiers = []
    for(const i in pageConfig.annual_tiers) {
      let tier = pageConfig.annual_tiers[i]
      tier['handleClick'] =  handlePricingFactory(tier.productId, tier.message)
      newTiers.push(tier)
    }
    setAnnualTiers(newTiers)
  }, [])




  return (
    <Box style={{
      marginTop: '2em'
    }}>
        <AlertDialogSlide open={open} setOpen={setOpen} message={message} productId={productId}></AlertDialogSlide>
        <LogInDialogSlide open={loginOpen} setOpen={setLoginOpen}></LogInDialogSlide>
          {/* End hero unit */}
        <Container maxWidth="md" component="main">
          <Container maxWidth="md" component="main">
          <Box style={{
            display: 'inline-flex',
            verticalAlign: 'baseline',
            marginBottom: '1em'
          }}>
            <Typography style={{
              marginTop: '.45em',
              fontWeight: 'bold'
            }}>Monthly</Typography>
            <Switch onChange={handleBillingOccurence} {...label} />
            <Typography style={{
              marginTop: '.45em',
              fontWeight: 'bold'
            }}>Annual</Typography>
          </Box>
          </Container>
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={tier.title === 'Enterprise' ? 12 : 6}
                md={4}
              >
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: 'center',
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'baseline',
                        mb: 2,
                      }}
                    >
                      <Typography component="h2" variant="h3" color="text.primary">
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        /{tier.occurence}
                      </Typography>
                    </Box>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth variant={tier.buttonVariant} onClick={tier.handleClick}>
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Link href="/login"><Typography style={{
            marginTop: '2em',
          }}>{pageConfig.sign_in_for_free}</Typography></Link>
          </Container>
    </Box>
  );
};
export default PricingNew;