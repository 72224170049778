import * as React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Twitter from '@mui/icons-material/Twitter';

import Email from '@mui/icons-material/Email';

import { IconButton } from '@mui/material';
import DiscordLogo from './DiscordLogo';
import * as config from '../config'


const Footer = ({user}) => {
  const currentYear = new Date().getFullYear();
  return (
    <div style={{
      marginTop: '4em'
    }}>
      <Divider/>
      <Box style={{
          marginLeft: '.5em'
        }}>
          <IconButton href="https://www.linkedin.com/company/ipdetective/" aria-label="Linkedin">
            <LinkedInIcon style={{color: config.PRIMARY_COLOR}} fontSize={'large'}></LinkedInIcon>
          </IconButton>
          <IconButton href="https://twitter.com/detective_ip" aria-label="Twitter">
            <Twitter style={{color: config.PRIMARY_COLOR}} fontSize={'large'}></Twitter>
          </IconButton>
          <IconButton href="https://discord.gg/c3zKzpBNJU" aria-label="Discord">
            <DiscordLogo height={35} width={35} color={config.PRIMARY_COLOR} style={{marginTop: '.12em'}}></DiscordLogo>
          </IconButton>
          <IconButton href="/contact" aria-label="Contact Us">
            <Email style={{color: config.PRIMARY_COLOR}} fontSize={'large'}></Email>
          </IconButton>
        </Box>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <Box style={{
          marginLeft: '.5em'
        }}>
            <Typography>© IPDetective.io {currentYear}</Typography>
        </Box>
        <Box style={{
          marginRight: '.5em'
        }}>
          <Typography>Check our <Link href="./terms">Terms of Service and Privacy Policy</Link></Typography>
        </Box>
      </div>
    </div>
  );
};
export default Footer;