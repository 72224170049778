import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = ({open, setOpen}) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Please login"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Log in to IPDetective to register with AppSumo
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button href={`/login`}>Login</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default function AppSumoRegister({user, config}) {
  const [open, setOpen] = React.useState(false)
  const [appSumoToken, setAppSumoToken] = React.useState("")
  const [alertVariant, setAlertVariant] = React.useState("info")
  const [alertMessage, setAlertMessage] = React.useState("")

  React.useEffect(() => {
    if (user.email == "" || user.email == undefined || user.email == null) {
      setOpen(true)
    }
  }, [])

  const handleRegisterAppSumo = () => {
    fetch(config.API_URL + `/registerAppSumo?appsumo-token=${appSumoToken}`, {
      method: "GET",
      headers: {
        "x-api-key": user.api_key
      },
    }).then(response => {
      response.json().then(body => {
        if ('error' in body && body.error != "") {
          setAlertVariant('error')
          setAlertMessage(`${body.error} error occured`)
          return
        }
        setAlertVariant('success')
        setAlertMessage("Successfully registered with AppSumo!")
        window.location.replace("/account")
      })
    })
  }

  return (
    <Container component="main" maxWidth="xs">
      <AlertDialogSlide open={open} setOpen={setOpen}/>
      <Box
        sx={{
          marginTop: 6,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Register AppSumo Token
        </Typography>
        <Typography variant='subtitle2'>
          Enter your AppSumo token below to register to IPDetective
        </Typography> 
        <Box sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="appSumoToken"
            label="App Sumo Token"
            name="app-sumo-token"
            autoFocus
            onChange={(e) => {
              setAppSumoToken(e.target.value)
            }}
          />
          { alertVariant !== 'info' &&
            <Alert style={{
              marginTop: '1em'
            }} severity={alertVariant}>{alertMessage}</Alert>
          }
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleRegisterAppSumo}
          >
            Register with AppSumo
          </Button>
        </Box>
      </Box>
    </Container>
  );
}